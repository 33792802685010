<template>
    <section class="c-product-header">
        <div
            ref="inner"
            class="c-product-header_inner"
        >
            <header
                ref="headerContent"
                class="c-product-header_content"
            >
                <h1 class="c-product-header_title | c-heading -wide">
                    <anim-text
                        tag="div"
                        :text="product.name"
                        :visible="isVisible"
                    />
                </h1>
                <div class="c-product-header_desc">
                    <span
                        class="c-product-header_down"
                        :class="{ 'is-visible' : isVisible }"
                    >
                        <app-button
                            arrow="down"
                            display="border"
                            @click="scrollToVideo"
                        />
                    </span>
                    <anim-text
                        :text="product.intro"
                        :separatorIcon="true"
                        :visible="isVisible"
                        class="o-text"
                    />
                </div>
            </header>
            <div
                ref="videoWrapper"
                class="c-product-header_video"
                :class="{ 'is-fullscreen' : isFullScreen }"
            >
                <icon
                    ref="plus"
                    name="plus"
                    class="c-product-header_plus"
                />
                <asset
                    ref="video"
                    :asset="product.videoCover"
                    @loaded="videoLoaded"
                />
            </div>
        </div>

        <div
            ref="content"
            class="c-content -heading"
        >
            <h2 class="c-content_title | c-heading -h1">
                <anim-text
                    v-for="(word, i) in product.heading.title"
                    :key="`product-headline-${i}`"
                    :visible="headlineVisibleState[i]"
                    tag="span"
                    :text="word"
                />
            </h2>
            <div class="c-content_media">
                <asset
                    ref="smallVideo"
                    :asset="product.heading.video"
                />
                <anim-text
                    :text="product.heading.desc"
                    :separatorIcon="true"
                    :visible="videoContentVisible"
                    class="c-content_media-desc | o-text"
                />
            </div>
        </div>
    </section>
</template>

<script>

import AnimText     from '@/templates/objects/AnimText'
import Asset        from '@/templates/objects/Asset'
import Icon         from '@/templates/objects/Icon'
import AppButton    from '@/templates/components/AppButton'

import { gsap }     from '@/gsap'

export default {
    name: 'ProductHeader',
    inject: ['store'],
    components: {
        AnimText,
        Asset,
        Icon,
        AppButton,
    },
    data: () => ({
        isVisible: false,
        videoContentVisible: false,
        isFullScreen: false,
        headlineVisibleState: []
    }),
    mounted() {
        this.headlineVisibleState = new Array(this.headlineTitleLength).fill(false)

        this.setTimeline()
    },
    methods: {
        setTimeline() {

            const objectProxy = {
                progress: 0
            }

            this.isVisible = true

            if(this.store.state.mobileLayout) {

                this.tl = gsap.timeline({
                    default: ({
                        duration: 2,
                        ease: 'none'
                    }),
                    scrollTrigger: {
                        trigger: this.$refs.inner,
                        start: 'top top',
                        end: 'bottom top',
                        scrub: true,
                        onEnter: () => {
                            this.tl.progress(0)
                            this.isVisible = true
                        },
                        onEnterBack: () => {
                            this.isVisible = true
                        },
                        onLeave: () => {
                            this.isVisible = false
                        },
                        onToggle: ({isActive}) => {
                            this.$refs.video.toggleVideo(isActive)
                        },
                        onUpdate: (state) => {
                            const p = gsap.utils.clamp(0, 1, 3 * (state.progress - .66))

                            if(p > 0) {
                                this.updateHeadlineState(p)
                            }
                        },
                    },
                })

                this.tl
                    .fromTo(objectProxy,
                        {
                            progress: 0,
                        },
                        {
                            progress: 1,
                            onUpdate: () => {
                                this.store.setProductHeaderTimelineProgress(objectProxy.progress)
                            }
                        }, 0)
                    .add(() => {
                        this.headlineVisibleState.fill(false)
                    })
                    .add(() => {}, '+=.5')

            } else {

                let scrollDirection
                const self = this

                this.tl = gsap.timeline({
                    defaults: {
                        duration: 2,
                        ease: 'none',
                    },
                    scrollTrigger: {
                        trigger: this.$el,
                        end: '+=400% top',
                        pin: true,
                        scrub: true,
                        onEnter: () => {
                            this.isVisible = true
                        },
                        onUpdate: ({direction}) => {
                            scrollDirection = direction
                        },
                        onToggle: ({isActive}) => {
                            this.$refs.video.toggleVideo(isActive)
                            this.$refs.smallVideo.toggleVideo(isActive)
                        }
                    },
                })

                this.tl
                    .fromTo(this.$refs.videoWrapper,
                        {
                            scale: .37,
                        },
                        {
                            scale: 1,
                        }, 0)
                    .fromTo(this.$refs.plus.$el,
                        {
                            scale: 16/5,
                        },
                        {
                            scale: 1,
                        }, 0)
                    .fromTo(objectProxy,
                        {
                            progress: 0,
                        },
                        {
                            progress: 1,
                            onUpdate: () => {
                                this.store.setProductHeaderTimelineProgress(objectProxy.progress)

                                // Fix visible scroll bug
                                this.isVisible = true
                            }
                        }, 0)
                    .add(() => {
                        this.isFullScreen = scrollDirection > 0
                    }, 1.7)
                    .add(() => {
                        // Toggle title/desc visibility
                        this.isVisible = scrollDirection < 0;

                        // Hide headline
                        this.headlineVisibleState.fill(false)

                        // Hide video content
                        this.videoContentVisible = false
                    })
                    .addLabel('videoFullscreen')
                    // Add video sticky delay
                    .fromTo(this.$refs.content,
                        {
                            y: '0',
                            yPercent: 0
                        },
                        {
                            y: '-100vh',
                            yPercent: -100,
                            delay: .5,
                            onUpdate: function() {
                                const progress = 2 * this.progress()
                                self.updateHeadlineState(progress)
                            }
                        })
                    .fromTo(this.$refs.videoWrapper,
                        {
                            yPercent: 0,
                        },
                        {
                            yPercent: -100,
                            duration: 1,
                        }, '<.5')
            }
        },
        updateHeadlineState(progress) {
            const index = Math.floor(progress * this.headlineTitleLength)

            // Show video content
            if(index === this.headlineTitleLength - 1) {
                this.videoContentVisible = true
            }

            // Show headline
            if(index >= 0 && index < this.headlineTitleLength) {

                for(let i = 0; i <= index; i++) {
                    this.headlineVisibleState[i] = true
                }
            }
        },
        videoLoaded() {
            this.store.setProductVideoLoaded(true)
        },
        scrollToVideo() {
            gsap.to(window, {
                scrollTo: {
                    y: this.store.state.mobileLayout ? window.innerHeight : 2 * window.innerHeight
                },
                duration: 1,
                overwrite: true,
            });
        }
    },
    computed: {
        product() {
            return this.store.state.currentProduct
        },
        headlineTitleLength() {
            return this.product.heading.title.length
        },
    },
    watch: {
        'store.state.mobileLayout' (newLayoutMobile, oldLayoutMobile) {

            // Reset timeline
            this.tl.scrollTrigger.kill(true)
            this.tl.kill(true)

            const animatedElements = []

            if(oldLayoutMobile) {
                // animatedElements.push(this.$refs.videoWrapper)
            } else {
                animatedElements.push(this.$refs.videoWrapper, this.$refs.plus.$el, this.$refs.content)
            }

            if(animatedElements.length > 0) {
                gsap.set(animatedElements, {
                    clearProps: 'all'
                })
            }

            this.setTimeline()
        },
    },
    unmounted() {
        this.tl.scrollTrigger.kill(true)
        this.tl.kill(true)

        this.store.setProductHeaderTimelineProgress(0)
    }
}

</script>

<style lang="scss">

.c-product-header {
    z-index: 1;

    .c-content {
        z-index: 3;
    }

    html.-layout-desktop & {
        margin-bottom: vh(-100);

        .c-content {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
        }
    }

    @media (orientation: portrait) {

        html.-layout-desktop & {
            margin-bottom: vh(-60);
        }
    }
}

.c-product-header_inner {
}

.c-product-header_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: vh(100);
    padding: rem(80px) var(--grid-gutter) var(--grid-gutter);

    html.is-ready & .o-at {
        --at-duration-out: 0s;
    }
}

.c-product-header_title {
    display: flex;
    align-items: flex-end;
    min-height: 1.8em;
    // margin-bottom: auto;
    height: calc(#{vh(50vh)} - var(--grid-gutter)/2 - #{rem(40px)});

    .o-at {
        --at-duration-in: 1.2s;
        --at-easing-in: #{$easing};
    }

    @media (max-aspect-ratio: 1/1) {
        height: auto;
        min-height: 0;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.c-product-header_desc {

    // position: absolute;
    // right: 0;
    // bottom: 0;
    margin-top: auto;

    .o-at {
        --at-delay-in: .8s;
    }

    @media (max-width: $to-small) {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;

        p {
            width: grid-space(6/10);
        }
    }

    @media (max-width: $to-tiny) {

        p {
            width: grid-space(8/10);
        }
    }

    @media (min-width: $from-small) {
        margin-left: auto;
    }

    @media (min-width: $from-small) and (max-width: $to-big) {
        width: grid-space(5/16);
    }

    @media (min-width: $from-big) {
        width: grid-space(4/16);
    }
}

.c-product-header_down {

    display: block;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // width: $accessible-size;
    // height: $accessible-size;
    margin-left: auto;
    text-align: right;
    opacity: 0;
    transform: scale(.5);
    transform-origin: calc(100% - #{$accessible-size/2}) 50%;
    transition: opacity .4s $easing, transform .4s $easing;

    .c-button {
        --button-height: #{$accessible-size};
    }

    // &:before {
    //     content: "";
    //     position: absolute;
    //     top: calc(#{$accessible-size/2} - #{rem(42px/2)});
    //     left: calc(#{$accessible-size/2} - #{rem(42px/2)});
    //     width: rem(42px);
    //     height: rem(42px);
    //     border: 1px solid currentColor;
    //     border-radius: 50%;
    //     transition: transform $speed $easing;
    // }

    // &:after {
    //     content: "\2193";
    // }

    html.is-ready &.is-visible {
        opacity: 1;
        transform: scale(1);
        transition: opacity .8s $easing .8s, transform .8s $easing .8s;

        // &:hover:before {
        //     transform: scale(1.1);
        // }

        // &:active:before {
        //     transform: scale(1.2);
        //     transition: transform .1s;
        // }
    }

    @media (max-width: $to-small) {
        right: calc(#{42px} - #{$accessible-size});
        bottom: calc(#{42px} - #{$accessible-size});
    }

    @media (min-width: $from-small) {
        margin-bottom: rem(10px);
    }
}

.c-product-header_video {
    z-index: 3;
    width: 100vw;
    height: vh(100);
    overflow: hidden;

    .o-asset {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;

        video {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
        }
    }

    html.-layout-desktop & {
        position: absolute;
        bottom: 0;
        left: 0;
        border-top-right-radius: $radius-large;
        transform-origin: 0 100%;
        transform: scale(.37);
        transition: border-radius $speed $easing;
        will-change: transform;

        .o-asset {

            video {
                transform: translate(0, 110%);
                transition: transform $speed-slow $easing;
            }
        }

        &.is-fullscreen {
            border-top-right-radius: 0;

            .c-product-header_plus {
                opacity: 0 !important;
            }
        }
    }

    html.-layout-desktop.is-ready & {

        .o-asset {

            video {
                transform: translate(0);
            }
        }
    }
}

.c-product-header_plus {
    z-index: 1;
    margin-top: 1em;
    margin-left: 1em;
    opacity: 0;
    transform-origin: 0 0;
    transition: opacity $speed $easing;

    html.-layout-mobile {
        display: none;
    }

    html.is-ready & {
        opacity: 1;
    }
}

</style>
