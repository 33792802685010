<template>
    <div
        class="o-asset"
        :style="`--asset-ratio: ${this.ratio};`"
        :class="className"
    >
        <img
            v-if="isImage"
            ref="asset"
            :src="src"
            :alt="alt"
            @loaded="loaded"
        >
        <video
            v-else
            ref="asset"
            :src="src"
            loop
            playsinline
            muted
            :autoplay="!toggleState"
            @canplaythrough="loaded"
        ></video>
    </div>
</template>

<script>

import { ScrollTrigger } from '@/gsap'

export default {
    name: 'Asset',
    emits: ['loaded'],
    data: () => ({
        src: '',
        isLoaded: false,
        isVisible: false,
    }),
    props: {
        asset: {
            type: Object,
            required: true
        },
        isImage: {
            type: Boolean,
            default: false
        },
        alt: {
            type: String,
            default: undefined
        },
        responsive: {
            type: Boolean,
            default: true
        },
        toggleState: {
            type: Boolean,
            default: false
        },
    },
    created() {
        if(this.isImage) {
            this.src = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${this.asset.width} ${this.asset.height}'%3E%3C/svg%3E`
        }
    },
    mounted() {

        this.setSrc()

        if(this.responsive && !this.isImage) {
            window.addEventListener('resizeEnd', this.onResize = this.setSrc())
        }

        if(this.toggleState) {
            this.st = ScrollTrigger.create({
                trigger: this.$refs.asset,
                start: this.isImage ? 'center bottom' : 'top bottom',
                onToggle: ({isActive}) => {
                    if(this.isImage) {
                        this.isVisible = isActive
                    } else {
                        this.toggleVideo(isActive)
                    }
                },
            })
        }
    },
    methods: {
        setSrc() {
            let asset

            if(!this.responsive || this.isImage) {
                asset = this.asset
            } else if(window.innerWidth/window.innerHeight < 1) {
                asset = this.asset.portrait
            } else {
                asset = this.asset.landscape
            }

            this.src = asset.src
        },
        toggleVideo(play) {
            if(this.isImage || !this.isLoaded) {
                return
            }

            if(play) {
                this.$refs.asset.play()
            } else {
                this.$refs.asset.pause()
            }
        },
        loaded() {
            this.isLoaded = true
            this.$emit('loaded')
        }
    },
    computed: {
        ratio() {
            let asset

            if(!this.responsive || this.isImage) {
                asset = this.asset
            } else if(window.innerWidth/window.innerHeight < 1) {
                asset = this.asset.portrait
            } else {
                asset = this.asset.landscape
            }

            return asset.width/asset.height
        },
        className() {
            let classname = this.isImage ? '-image' : '-video'

            if(this.toggleState) {
                classname += ' -toggle-state'
            }

            if(this.isVisible) {
                classname += ' is-visible'
            }

            return classname
        },
    },
    unmounted() {

        if(this.toggleState) {
            this.st.kill()
        }

        if(this.responsive && !this.isImage) {
            window.removeEventListener('resizeEnd', this.onResize)
        }
    }
}

</script>

<style lang="scss">

.o-asset {
    --asset-width: 100%;
    --asset-ratio: 16/9;

    width: var(--asset-width);

    &.-video {
        height: 0;
        padding-top: calc(var(--asset-width)/var(--asset-ratio));

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // object-fit: cover;
            // object-position: 50% 50%;

            &::-internal-media-controls-overlay-cast-button {
                display: none;
            }
        }
    }

    &.-image {

        img {
            display: block;
            width: 100%;
            height: auto;
        }

        &.-toggle-state {
            opacity: 0;
            transition: opacity $speed-slow $easing;

            &.is-visible {
                opacity: 1;
            }
        }
    }
}

</style>
