<template>
    <section
        class="c-sequence"
        :class="{ 'is-visible' : isVisible }"
        :style="styles"
    >
        <div
            ref="container"
            class="c-sequence_container"
        >
            <div
                ref="inner"
                class="c-sequence_inner"
            >
                <canvas
                    ref="canvas"
                    class="c-sequence_canvas"
                ></canvas>

                <ul class="c-sequence_content">
                    <li
                        v-for="(highlight, i) in data.highlights"
                        :key="`sequence-highlight-${i}`"
                        class="c-sequence_item"
                    >
                        <div class="c-sequence_highlight">
                            <anim-text
                                tag="h3"
                                :text="highlight.title"
                                type="chars"
                                :visible="activeHighlightIndex === i"
                                class="c-sequence_highlight | c-heading -h2"
                            />
                        </div>
                        <anim-text
                            :text="highlight.desc"
                            :separator="true"
                            :visible="activeHighlightIndex === i"
                            class="c-sequence_desc | o-text"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>

import AnimText from '@/templates/objects/AnimText'

import { gsap } from '@/gsap'

export default {
    name: 'Sequence',
    inject: ['store'],
    components: {
        AnimText
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        isVisible: false,
        preloadStep: 16, // MUST be a power of 2
        activeHighlightIndex: -1,
        currentFrameIndex: -1,
    }),

    mounted() {

        this.sequenceFrames = []

        for (let i = 0; i <= this.length; i++) {
            this.sequenceFrames[i] = {
                image: new Image(),
                isLoaded: false,
                src: `${this.data.path}${this._pad(i, 3)}.jpg`
            }
        }

        // // Load sequence
        // this.loadSequence();

        // Canvas
        this.$canvas = this.$refs.canvas;
        this.context = this.$canvas.getContext('2d');
        this.setCanvasSizes()

        // Highlight start/end positions
        this.highlightsArray = this.data.highlights.map(highlight => (
            {
                start: highlight.start,
                end: highlight.end
            }
        ))

        this.tl = gsap.timeline({
            defaults: {
                duration: .5,
                ease: 'none'
            },
            scrollTrigger: {
                trigger: this.$el,
                start: 'top center',
                end: 'bottom bottom',
                pin: this.$refs.container,
                pinReparent: true,
                scrub: true,
            },
        })

        const sequenceProxy = {
            frame: 0
        }

        this.tl
            .fromTo(this.$refs.inner,
                {
                    yPercent: 0,
                    opacity: 0,
                },
                {
                    yPercent: -50,
                    opacity: 1,
                })
            .to(sequenceProxy, {
                frame: this.length,
                snap: 'frame',
                duration: this.length/20,
                onUpdate: () => {
                    this.setProgress(sequenceProxy.frame)
                }
            })
            .fromTo(this.$refs.inner,
                {
                    opacity: 1,
                },
                {
                    opacity: 0,
                    onStart: () => {
                        this.activeHighlightIndex = -1
                    }
                })

        // Window events
        window.addEventListener('resizeEnd', this.onResize = () => this.setCanvasSizes())
    },

    methods: {

        setCanvasSizes() {
            this.$canvas.width = window.innerWidth
            this.$canvas.height = window.innerHeight
        },

        // Recursive image load, from preloadStep to half by half until 1
        loadSequence(firstLoop=true) {

            const imagesToLoad = []
            const step = this.preloadStep

            this.sequenceFrames.forEach((f, i) => {

                if(     (firstLoop && i % step === 0)
                    ||  (!firstLoop && i > 0 && i % step === 0 && i/2 % step !== 0)) {
                    imagesToLoad.push(this.loadImage(f, i))
                }
            })

            Promise.all(imagesToLoad).then(() => {

                if(step > 1) {
                    this.preloadStep = step/2
                    this.loadSequence(false)
                } else {
                    console.log('All images are loaded 🚨');
                }
            })
        },

        loadImage(frame, i) {

            return new Promise(resolve => {

                // Set image source
                frame.image.src = frame.src;

                // Callback on image load
                const imageLoad = () => {

                    // Set sequence loaded
                    frame.isLoaded = true;

                    // Show frame on first image load
                    if(i === 0) {
                        this.setProgress(0);
                    }

                    frame.image.removeEventListener('load', imageLoad)

                    // Resolve promise
                    resolve()
                }

                frame.image.addEventListener('load', imageLoad)
            })
        },

        setProgress(frameIndex) {

            // Return if same frame
            if(frameIndex == this.currentFrameIndex) {
                return
            }

            let frame = this.sequenceFrames[frameIndex]

            if(!frame.isLoaded) {
                const clostsPreloadedIndex = this._roundDown(frameIndex, this.preloadStep * 2)
                const closestPreloadedFrame = this.sequenceFrames[clostsPreloadedIndex]
                frame = closestPreloadedFrame
            }

            // Draw image into canvas
            if(frame.isLoaded) {
                const image = frame.image
                // Get scale
                const scale = Math.max(this.$canvas.width / image.width, this.$canvas.height / image.height);
                // Get the top left position of the image
                const x = (this.$canvas.width / 2) - (image.width / 2) * scale;
                const y = (this.$canvas.height / 2) - (image.height / 2) * scale;

                // Uncomment if image has transparency
                // this.context.clearRect(0, 0, this.$canvas.width, this.$canvas.height);

                // Draw image in cover size
                this.context.drawImage(image, x, y, image.width * scale, image.height * scale);
            }

            // Detect show/hide highlight
            this.activeHighlightIndex = this.highlightsArray.findIndex(highlight => frameIndex >= highlight.start && frameIndex <= highlight.end)

            // Update currentFrameIndex
            this.currentFrameIndex = frameIndex
        },

        // Prepend leading 0 to number
        _pad(x, width) {
            return String(x).padStart(width, '0')
        },

        // Rounding down number
        _roundDown(num, precision) {
            num = parseFloat(num);
            return !precision ? num : Math.floor(num / precision) * precision
        }
    },

    computed: {
        length() {
            return this.data.length
        },
        styles() {
            return `--sequence-length: ${this.length};`
        }
    },

    watch: {
        'store.state.isReady': function(ready) {
            if(ready) {
                this.loadSequence();
            }
        }
    },

    unmounted() {

        // Prevent images from loading
        for (let i = 0; i <= this.length; i++) {
            this.sequenceFrames[i].image.src = ''
        }

        // Window events
        window.removeEventListener('resizeEnd', this.onResize)

        this.tl.kill()
    }
}

</script>

<style lang="scss">

.c-sequence {
    height: calc(var(--sequence-length)/20 * #{vh(100)} + #{vh(100)});
}

.c-sequence_container {
    width: 100%;
    height: vh(100);
}

.c-sequence_inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.c-sequence_canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.c-sequence_content {
    z-index: 1;
    position: static;
}

.c-sequence_item {
    --sequence-item-columns: repeat(2, minmax(0, 1fr));

    position: absolute;
    left: 0;
    display: grid;
    grid-gap: var(--grid-gutter);
    grid-template-columns: var(--sequence-item-columns);
    width: 100%;
    padding: var(--grid-gutter);

    @media (max-width: $to-tiny) {
        top: 60%;
        grid-template-areas: "title desc";
    }

    @media (min-width: $from-tiny) {
        --sequence-item-columns: #{grid-space(4/10)} #{grid-space(6/10)};

        bottom: 0;
        grid-template-areas: "desc title";
    }

    @media (min-width: $from-small) {
        --sequence-item-columns: #{grid-space(5/16)} #{grid-space(11/16)};
    }

    @media (min-width: $from-medium) {
        --sequence-item-columns: #{grid-space(4/16)} #{grid-space(12/16)};
    }
}

.c-sequence_highlight {
    grid-area: title;

    @media (min-width: $from-tiny) {
        bottom: -.2em;
        text-align: right;
    }
}

.c-sequence_desc {
    --at-delay-in: .6s;

    grid-area: desc;
    margin-top: auto;

    @media (max-width: $to-tiny) {
        padding-top: 20vw;
    }
}

</style>
