<template>
    <div class="o-rise">
        <span
            v-for="i in 9"
            :key="`stagger-${i}`"
            class="o-rise_text"
        >
            Rise
        </span>
    </div>
</template>

<script>

import { gsap } from '@/gsap'

export default {
    name: 'Rise',
    mounted() {
        this.tl = gsap.timeline({
            scrollTrigger: {
                trigger: this.$el,
                start: 'top bottom+=50%',
                end: 'bottom+=30% top',
                scrub: true,
            }
        })

        const $items = Array.from(this.$el.children)

        const itemY = [-56.25, -48.75, -37.5, -22.5, 37.5, 32.5, 25, 15, 0]

        for(let index in $items) {

            this.tl.fromTo($items[index],
                {
                    yPercent: -50,
                },
                {
                    yPercent: itemY[index],
                    duration: 1,
                    ease: 'none'
                }, 0)
        }
    },
    unmounted() {
        this.tl.kill()
    }
}

</script>

<style lang="scss">

.o-rise {
    z-index: 1;
    width: 100%;
    margin-bottom: .35em;
    line-height: .83;
    font-family: ff("wide");
    font-size: 30.5vw;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: $from-big) {
        font-size: 31vw;
    }
}

.o-rise_text {
    display: block;
    background-color: $color-darkest;
    will-change: transform;

    &:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
    }
}

</style>
