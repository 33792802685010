<template>
    <footer class="c-product-footer">
        <div class="c-product-footer_inner">
            <nav class="c-product-footer_nav | c-heading -h3">
                <anim-text
                    v-for="(item, i) in footerNav"
                    :key="`footer-nav-item-${i}`"
                    type="chars"
                    tag="router-link"
                    :to="item.url"
                    :text="item.label"
                    :visible="isVisible"
                    class="c-product-footer_name | o-link"
                    v-hover-sound
                />
            </nav>
            <div class="c-product-footer_content">
                <ul class="c-product-footer_links">
                    <li
                        v-for="(link, i) in links"
                        :key="`footer-links-${i}`"
                    >
                        <a
                            :href="link.url"
                            class="o-link"
                            v-hover-sound
                        >
                            {{ link.title }}
                        </a>
                    </li>
                </ul>
                <socials />
            </div>
        </div>
        <div class="c-product-footer_bottom">
            <anim-text
                tag="small"
                :text="`&copy; ${currentYear} dr. dabber. All right reserved.`"
                :visible="isVisible"
                class="c-product-footer_copy | o-text -small"
            />
            <span class="c-product-footer_link">
                <anim-text
                    tag="a"
                    href="https://www.drdabber.com/pages/terms-of-use"
                    text="Legal"
                    :visible="isVisible"
                    class="o-text -small | o-link"
                    v-hover-sound
                />
            </span>
            <span class="c-product-footer_link">
                <anim-text
                    tag="a"
                    href="https://www.drdabber.com/pages/privacy-policy"
                    text="Privacy policy"
                    :visible="isVisible"
                    class="o-text -small | o-link"
                    v-hover-sound
                />
            </span>
            <anim-text
                tag="a"
                href="https://locomotive.ca"
                text="Website by Locomotive"
                :visible="isVisible"
                class="c-product-footer_credit | o-text -small | o-link"
                v-hover-sound
            />
        </div>
    </footer>
</template>

<script>

import Socials      from '@/templates/objects/Socials'
import AnimText     from '@/templates/objects/AnimText'

import { ScrollTrigger }    from '@/gsap'

const $html = document.documentElement

export default {
    name: 'ProductFooter',
    inject: ['store'],
    components: {
        Socials,
        AnimText,
    },
    data: () => ({
        isVisible: false,
        links: [
            {
                title: 'Vaporizers',
                url: 'https://www.drdabber.com/collections/vaporizers'
            },
            {
                title: 'Accessories',
                url: 'https://www.drdabber.com/collections/accessories'
            },
            {
                title: 'Atomizers',
                url: 'https://www.drdabber.com/collections/atomizers'
            },
            {
                title: 'Glassworks',
                url: 'https://www.drdabber.com/collections/glassworks'
            },
            {
                title: 'Wholesale',
                url: 'https://www.drdabber.com/pages/wholesale'
            },
            {
                title: 'Blog',
                url: 'https://www.drdabber.com/blogs/news'
            },
            {
                title: 'About us',
                url: 'https://www.drdabber.com/pages/about-us'
            },
            {
                title: 'FAQ',
                url: 'https://www.drdabber.com/pages/faq'
            },
            {
                title: 'Support',
                url: 'https://www.drdabber.com/pages/support'
            }
        ]
    }),
    mounted() {

        // Scroll trigger
        this.scrollTrigger = ScrollTrigger.create({
            trigger: this.$el,
            start: 'center bottom',
            end: 'bottom top',
            onToggle: ({isActive}) => {
                if(isActive) {
                    $html.classList.add('-mode-primary')
                } else {
                    $html.classList.remove('-mode-primary')
                }
                this.isVisible = isActive
            }
        })

    },
    computed: {
        currentYear: () => new Date().getFullYear().toString(),
        footerNav() {

            // Add products
            const nav = this.store.state.products.map(p => (
                {
                    label: p.name,
                    url: `/product/${p.id}`
                }
            ))

            // Add home to first index
            nav.unshift({
                label: 'Home',
                url: '/'
            })

            return nav
        }
    },
    unmounted() {
        this.scrollTrigger.kill()
        $html.classList.remove('-mode-primary')
    }
}

</script>

<style lang="scss">

.c-product-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: var(--grid-gutter);
    color: $color-dark;

    @media (orientation: portrait) {
        min-height: vh(50);
    }

    @media (orientation: landscape) {
        min-height: vh(100);
    }

    @media (max-width: $to-small) {
        padding-right: var(--grid-gutter);
        padding-left: var(--grid-gutter);
    }

    @media (min-width: $from-small) {
        padding-right: var(--grid-gutter);
        padding-left: var(--grid-gutter);
    }
}

.c-product-footer_inner {
    margin-top: auto;
    margin-bottom: auto;
    padding-top: var(--header-height);
    padding-bottom: var(--header-height);

    @media (min-width: $from-small) {
        display: grid;
        grid-column-gap: var(--grid-gutter);
        grid-template-columns: grid-space(10/16) grid-space(6/16);
    }
}

// Navigation
.c-product-footer_nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: $to-small) {
        padding-bottom: vh(5);
        padding-left: .6em;
    }

    @media (min-width: $from-small) {
        padding-left: grid-space(1/16, 1);
    }
}

.c-product-footer_name {
    display: block;

    &:after {
        left: .05em;
        height: .075em;
    }

    &:not(:first-child) {
        margin-top: .1em;

        &:after {
            right: .45em;
        }
    }

    @for $i from 1 through 4 {

        &:nth-child(#{$i}) {
            $i: $i - 1;
            --at-delay-in: #{$i/6}s;
        }
    }

    &.is-current {
        pointer-events: none;
        cursor: initial;

        &:before {
            content: "\2192";
            position: absolute;
            bottom: 0;
            right: 100%;
            opacity: 0;
            transform: translate(-100%, 0);
            transition:
                opacity var(--at-duration-out) var(--at-easing-out) var(--at-delay-out),
                transform var(--at-duration-out) var(--at-easing-out) var(--at-delay-out);
        }

        &.is-visible:before {
            opacity: 1;
            transform: translate(0);
            transition:
                opacity var(--at-duration-in) var(--at-easing-in) var(--at-delay-in),
                transform var(--at-duration-in) var(--at-easing-in) var(--at-delay-in);
        }
    }
}

// Content
.c-product-footer_content {

    .o-socials {
        margin-top: auto;
    }

    @media (min-width: $from-small) {
        display: flex;
        flex-direction: column;
    }
}

.c-product-footer_links {
    display: grid;
    grid-column-gap: var(--grid-gutter);

    a {
        font-size: rem(14px);
        font-weight: 700;
    }

    @media (max-width: $to-small) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        padding-top: vh(5);
        padding-bottom: vh(5);
    }

    @media (min-width: $from-small) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        // margin-top: auto;
        // padding-top: rem(30px);
        padding-bottom: rem(30px);
    }
}

// Bottom
.c-product-footer_bottom {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    padding-top: var(--grid-gutter);

    a {
        display: inline-block;
    }
}

.c-product-footer_copy {

    @media (max-width: $to-small) {
        width: 100%;
        margin-bottom: var(--grid-gutter-half);
    }

    @media (min-width: $from-small) {
        width: grid-space(10/16);
    }
}

.c-product-footer_link {

    @media (max-width: $to-small) {
        min-width: grid-space(2.5/10, 1)
    }

    @media (min-width: $from-small) {
        min-width: grid-space(1/16);
        margin-left: var(--grid-gutter);
    }
}

.c-product-footer_credit {

    @media (min-width: $from-small) {
        margin-left: auto;
    }
}


</style>
