<template>
    <div
        v-if="product"
        :key="product.id"
        class="c-product"
        :class="`-${product.id}`"
    >
        <product-header />

        <section>

            <div class="c-content -large">
                <div class="c-content_media">
                    <asset
                        :asset="product.video1.landscape"
                        :responsive="false"
                        :toggle-state="!store.isMobile"
                    />
                </div>
                <div class="c-content_inner">
                    <div class="c-content_text"
                    >
                        <anim-text
                            :text="product.text1"
                            :reveal="true"
                        />
                        <anim-text
                            :text="product.text2"
                            :reveal="true"
                        />
                    </div>
                    <div class="c-content_button">
                        <app-button
                            tag="a"
                            :url="product.url"
                            :label="`Buy the ${product.name}`"
                            :stretch="true"
                            display="primary"
                        />
                    </div>
                </div>
            </div>

            <div class="c-content -center">
                <div class="c-content_media">
                    <asset
                        :asset="product.video2"
                        :responsive="false"
                        :toggle-state="!store.isMobile"
                    />
                </div>
            </div>
        </section>

        <rise />

        <sequence :data="product.sequence" />

        <features :features="product.features" />

        <shop :product="product" />

        <shop-collection :collection="product.collection" />

        <product-footer />

    </div>
</template>

<script>

import Rise             from '@/templates/objects/Rise'
import AnimText         from '@/templates/objects/AnimText'
import Asset            from '@/templates/objects/Asset'
import ProductHeader    from '@/templates/components/ProductHeader'
import ProductFooter    from '@/templates/components/ProductFooter'
import Sequence         from '@/templates/components/Sequence'
import Features         from '@/templates/components/Features'
import Shop             from '@/templates/components/Shop'
import ShopCollection   from '@/templates/components/ShopCollection'
import AppButton        from '@/templates/components/AppButton'

export default {
    name: 'Product',
    inject: ['store'],
    components: {
        Rise,
        AnimText,
        Asset,
        ProductHeader,
        ProductFooter,
        Sequence,
        Features,
        Shop,
        ShopCollection,
        AppButton,
    },
    beforeMount() {
        if(typeof this.product === 'undefined') {
            this.$router.push({ path: '/play' })
        }
    },
    computed: {
        product() {
            return this.store.getProductById(this.$route.params.id)
        }
    },
}
</script>

<style lang="scss">

.c-product {
}

</style>
