<template>
    <section
        v-if="totalFeatures > 0"
        class="c-features"
    >
        <div class="c-features_inner">
            <article
                v-for="(feature, i) in features"
                :key="`product-feature-${i}`"
                class="c-features_item"
            >
                <figure class="c-features_fig">
                    <asset
                        :asset="feature.image"
                        :alt="feature.title"
                        :is-image="true"
                        :toggle-state="true"
                        class="c-features_image"
                    />
                </figure>
                <div class="c-features_content">
                    <anim-text
                        tag="h3"
                        :text="feature.title"
                        type="chars"
                        :reveal="true"
                        class="c-features_title | c-heading -h5"
                    />
                    <anim-text
                        :text="feature.desc"
                        :reveal="true"
                        class="c-features_desc"
                    />
                </div>
            </article>
        </div>
    </section>
</template>

<script>

import Asset    from '@/templates/objects/Asset'
import AnimText from '@/templates/objects/AnimText'

export default {
    name: 'Features',
    components: {
        Asset,
        AnimText
    },
    props: {
        features: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        totalFeatures() {
            return this.features.length
        },
    },
}

</script>

<style lang="scss">

.c-features {
    padding-right: var(--grid-gutter);
    padding-left: var(--grid-gutter);
}

.c-features_inner {

    @media (max-width: $to-small) {
        padding-right: grid-space(1/10, 1);
        padding-left: grid-space(1/10, 1);
    }

    @media (min-width: $from-small) {
        display: grid;
        grid-auto-rows: 1fr;
        grid-column-gap: var(--grid-gutter);
        grid-template-columns: repeat(2, minmax(0, 1fr));
        padding-right: grid-space(1/16, 1);
        padding-left: grid-space(1/16, 1);

        &:before {
            content: "";
            grid-row: 1/2;
            grid-column: 2/3;
        }
    }

    @media (min-width: $from-medium) {
        grid-column-gap: grid-space(2/16, 2);
        padding-right: grid-space(2/16, 1);
        padding-left: grid-space(2/16, 1);
    }
}

.c-features_item {

    @media (max-width: $to-small) {

        &:not(:first-child) {
            margin-top: vh(10);
        }
    }

    @media (min-width: $from-small) {
        grid-row: 2 span;

        &:not(:first-child) {
            margin-top: vh(20);
        }
    }
}

.c-features_content {
}

.c-features_title {
    margin-bottom: 1em;
}

.c-features_desc {
    --at-delay-in: .6s;
}

.c-features_fig {
    margin-bottom: vh(5);

    img {
        width: 100%;
    }

    @media (min-width: $from-small) {
        border-radius: $radius-medium;
        overflow: hidden;
    }
}

</style>
