<template>
    <section
        v-if="collection.items.length > 0"
        class="c-shop-collection"
    >
        <header class="c-shop-collection_header">
            <anim-text
                tag="h2"
                :text="collection.title"
                :visible="true"
                class="c-shop-collection_title | c-heading -h6"
            />
            <app-button
                tag="a"
                :url="collection.url"
                label="Shop all"
                display="darkest"
                class="c-shop-collection_button"
            />
            <span
                ref="progress"
                class="c-shop-collection_progress"
                :style="[
                    `--shop-collection-slider-progress :${sliderProgress}`,
                    `--shop-collection-slider-items :${collection.items.length}`,
                ]"
            ></span>
        </header>
        <div
            ref="slider"
            class="o-slider"
            :class="{ 'is-dragging' : isDragging }"
        >
            <article
                v-for="(slide, i) in collection.items"
                :key="`collection-product-${i}`"
                class="o-slider_slide"
            >
                <figure class="o-slider_fig">
                    <img
                        :src="slide.image"
                        :alt="slide.title"
                    >
                </figure>
                <div class="o-slider_content">
                    <h3 class="o-slider_name">
                        {{ slide.title }}
                    </h3>
                    <span
                        v-if="slide.discount"
                        class="o-slider_price"
                    >
                        {{ slide.discount }} USD
                    </span>
                    <span
                        class="o-slider_price"
                        :class="{ '-sale' : slide.discount }"
                    >
                        {{ slide.price }} {{ slide.discount ? '' : slide.currency }}
                    </span>
                </div>
                <a
                    :href="slide.url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="o-slider_link"
                    v-hover-sound
                >
                    Buy {{ slide.title }}
                </a>
            </article>
        </div>
    </section>
</template>

<script>

import AnimText     from '@/templates/objects/AnimText'
import AppButton    from '@/templates/components/AppButton'

import Flickity                 from 'flickity';
import { gsap, ScrollTrigger }  from '@/gsap'

const $html = document.documentElement

export default {
    name: 'ShopCollection',
    inject: ['store'],
    components: {
        AnimText,
        AppButton,
    },
    props: {
        collection: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        sliderProgress: 0,
        isDragging: false,
        products: []
    }),
    mounted() {

        if(this.store.state.shopifyProductsLoaded) {
            this.init()
        }
    },
    methods: {
        init() {

            // Scroll trigger
            this.scrollTrigger = ScrollTrigger.create({
                trigger: this.$el,
                start: 'top bottom',
                end: 'bottom top',
                onToggle: ({isActive}) => {
                    if(isActive) {
                        $html.classList.add('-mode-light')
                    } else {
                        $html.classList.remove('-mode-light')
                    }
                }
            })

            this.flickity = new Flickity(this.$refs.slider, {
                freeScroll: true,
                contain: true,
                cellAlign: 'left',
                prevNextButtons: false,
                pageDots: false,
                on: {
                    ready: () => {
                        ScrollTrigger.refresh(true)
                    },
                    scroll: progress => {
                        this.sliderProgress = gsap.utils.clamp(0, 1, progress)
                    },
                    dragStart: () => {
                        this.isDragging = true
                    },
                    dragEnd: () => {
                        this.isDragging = false
                    }
                }
            })
        },
    },
    watch: {
        'store.state.shopifyProductsLoaded': function(loaded) {
            if(loaded) {
                this.init()
            }
        }
    },
    unmounted() {
        this.scrollTrigger.kill()
        this.flickity.destroy()
        $html.classList.remove('-mode-light')
    }
}

</script>

<style lang="scss">

.c-shop-collection {

    .o-at {
        --at-duration-out: 0s;
    }
}

.c-shop-collection_header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-right: var(--grid-gutter);
    padding-left: var(--grid-gutter);

    @media (max-width: $to-small) {
        justify-content: space-between;
    }

    @media (min-width: $from-small) {

        .c-button {
            margin-left: var(--grid-gutter);
        }
    }
}

.c-shop-collection_title {

//    @media (max-width: $to-tiny) {
//        width: 100%;
//        margin-bottom: 1em;
//    }
//
//    @media (min-width: $from-tiny) {
//        margin-right: 1em;
//    }
}

.c-shop-collection_button {}

.c-shop-collection_progress {
    --shop-collection-slider-progress: 0;

    display: block;
    width: grid-space(2/16);
    height: 1px;
    margin-left: auto;
    background-color: $color-gray;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100%/var(--shop-collection-slider-items));
        height: 100%;
        background-color: $color-primary;
        // transform: scale(var(--shop-collection-slider-progress), 1);
        // transform-origin: 0 50%;
        transform: translate(calc(100% * var(--shop-collection-slider-progress) * (var(--shop-collection-slider-items) - 1)), 0);
        transition: transform .1s;
    }

    @media (max-width: $to-small) {
        display: none;
    }
}

.c-shop-collection_slider {}



/*----------  Slider  ----------*/

.o-slider {
    margin-top: rem(20px);
    padding-right: var(--grid-gutter-half);
    padding-left: var(--grid-gutter-half);
    overflow: hidden;

    &.is-dragging {

        a {
            pointer-events: none;
        }
    }

    .flickity-viewport {
        overflow: visible;
    }
}

.o-slider_slide {
    padding-right: var(--grid-gutter-half);
    padding-left: var(--grid-gutter-half);

    &:hover {

        .o-slider_fig {

            img {
                filter: brightness(1.5);
                transform: scale(1.1);
            }
        }
    }

    @media (max-width: $to-tiny) {
        width: grid-space(8/10, 1);
    }

    @media (min-width: $from-tiny) {
        width: grid-space(4/10, 1);
    }

    @media (min-width: $from-small) {
        width: grid-space(6/16, 1);
    }

    @media (min-width: $from-medium) {
        width: grid-space(5/16, 1);
    }
}

.o-slider_fig {
    height: 0;
    padding-top: 100%;
    background-color: $color-darkest;
    border-radius: $radius-large;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: 50% 50%;
        padding: 15%;
        transition: filter $speed $easing, transform $speed $easing;
    }
}

.o-slider_content {
    padding-top: rem(10px);
    padding-bottom: rem(10px);
}

.o-slider_name {
    display: block;
    color: $color-lightest;
    transition: color .4s ease-out;

    html.-mode-primary & {
        color: $color-dark;
    }
}

.o-slider_price {
    display: inline-block;

    &.-sale {
        margin-left: 1em;
        color: $color-gray;
        text-decoration: line-through;
    }
}

.o-slider_link {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    font-size: 0;
}


</style>
