<template>
    <section class="c-shop">
        <div class="c-shop_inner">
            <h2
                ref="title"
                class="c-shop_title | c-heading -wide"
            >
                <anim-text
                    tag="div"
                    :text="product.name"
                    :visible="isVisible"
                />
            </h2>
            <div class="c-shop_price">
                <anim-text
                    tag="span"
                    :text="`$${product.price}`"
                    type="chars"
                    :visible="isVisible"
                    class="c-heading -h4"
                />
            </div>
            <div class="c-shop_desc">
                <anim-text
                    ref="desc"
                    :text="product.shopDesc || product.intro"
                    :visible="isVisible"
                    class="o-text -lower"
                />
            </div>
            <div
                class="c-shop_button"
                :class="{ 'is-visible' : isVisible }"
            >
                <app-button
                    tag="a"
                    :url="product.url"
                    :label="`Buy the ${product.name}`"
                    display="primary"
                    :stretch="true"
                />
            </div>
        </div>
    </section>
</template>

<script>

import AnimText     from '@/templates/objects/AnimText'
import AppButton    from '@/templates/components/AppButton'

import { gsap, ScrollTrigger } from '@/gsap'

export default {
    name: 'Shop',
    inject: ['store'],
    components: {
        AnimText,
        AppButton,
    },
    props: {
        product: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        isVisible: false,
    }),
    mounted() {
        this.scrollTrigger = ScrollTrigger.create({
            trigger: this.$el,
            start: 'top bottom',
            end: 'bottom top',
            onUpdate: state => {
                if(state.direction > 0 && state.progress > .4 && !this.isVisible) {
                    this.isVisible = true
                } else if(state.direction < 0 && state.progress === 0 && this.isVisible) {
                    this.isVisible = false
                }

                const progress = gsap.utils.clamp(0, 1, 1.25 * state.progress - 0.1)
                this.store.setProductShopTimelineProgress(progress)
            },
        })
    },
    unmounted() {
        this.scrollTrigger.kill()
    }
}

</script>

<style lang="scss">

.c-shop {
    display: flex;
    min-height: vh(50);
    align-items: center;
    padding: vh(15) var(--grid-gutter);

    .o-at {
        --at-duration-out: 0s;
    }

    @media (orientation: portrait) {
        min-height: vh(50);
    }

    @media (orientation: landscape) {
        min-height: vh(75);
    }
}

.c-shop_inner {
    display: grid;
    grid-gap: var(--content-margin-y) var(--grid-gutter);
    width: 100%;

    @media (max-width: $to-tiny) {
        grid-template-areas:
            'title  title'
            'price  desc'
            'button button';
    }

    @media (min-width: $from-tiny) and (max-width: $to-medium) {
        grid-template-areas:
            'title  title'
            'price  desc'
            '.      button';
    }

    @media (min-width: $from-medium) {
        grid-template-areas:
            'title  title  title  title  title'
            '.      price  desc   button .';
    }

    @media (max-width: $to-medium) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: $from-medium) and (max-width: $to-big) {
        grid-template-columns: grid-space(3/16) grid-space(3/16) grid-space(4/16) grid-space(3/16) grid-space(3/16);
    }

    @media (min-width: $from-big) {
        grid-template-columns: grid-space(3/16) grid-space(3/16) grid-space(4/16) grid-space(2/16) grid-space(4/16);
    }
}

.c-shop_title {
    grid-area: title;
}

.c-shop_price {
    grid-area: price;
    display: flex;
    flex-direction: column;

    .o-at {
        --at-delay-in: .4s;
    }
}

.c-shop_desc {
    grid-area: desc;

    .o-at {
        --at-delay-in: .6s;
    }

    @media (min-width: $from-medium) and (max-width: $to-big) {
        padding-right: grid-space(1/16, 1);
    }

    @media (min-width: $from-big) {
        padding-right: grid-space(2/16, 1);
    }
}

.c-shop_button {
    grid-area: button;
    opacity: 0;

    &.is-visible {
        opacity: 1;
        transition: opacity $speed $easing .8s
    }

    .c-button {
        width: 100%;
    }
}

</style>
