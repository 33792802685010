<template>
    <ul class="o-socials">
        <li
            v-for="social in socials"
            :key="`socials-${social.name}`"
            class="o-socials_item"
        >
            <a
                :href="social.url"
                :title="social.name"
                class="o-socials_link"
                target="_blank"
                rel="noopener noreferrer"
                v-hover-sound
            >
                <icon
                    :name="social.icon"
                    :title="social.name"
                />
            </a>
        </li>
    </ul>
</template>

<script>

import Icon from '@/templates/objects/Icon'

export default {
    name: 'Socials',
    components: {
        Icon,
    },
    data: () => ({
        socials: [
            {
                name: 'Instagram',
                icon: 'social-instagram',
                url: 'https://www.instagram.com/drdabber/'
            },
            {
                name: 'Youtube',
                icon: 'social-youtube',
                url: 'https://www.youtube.com/channel/UC0NVkIgwKy-ttYw9RSumj6w'
            },
            {
                name: 'Facebook',
                icon: 'social-facebook',
                url: 'https://www.facebook.com/drdabber/'
            },
            {
                name: 'Twitter',
                icon: 'social-twitter',
                url: 'https://twitter.com/drdabber'
            },
            {
                name: 'Linkedin',
                icon: 'social-linkedin',
                url: 'https://www.linkedin.com/company/dr.-dabber/'
            },
            {
                name: 'Discord',
                icon: 'social-discord',
                url: 'https://discord.st/server/drdabber/'
            },
        ]
    }),
}

</script>

<style lang="scss">

.o-socials {
    display: inline-flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    &:hover {

        .o-socials_link {
            color: rgba($color-dark, .3);
            filter: blur(1px);

            &:hover {
                color: $color-dark;
                filter: blur(0);
            }
        }
    }
}

.o-socials_item {
    flex-shrink: 0;
}

.o-socials_link {
    display: block;
    padding: 10px;
    transition: all $speed-slow $easing;

    .o-icon {
        display: block;
    }

    svg {
        fill: currentColor;
    }
}

</style>
